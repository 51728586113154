import React from 'react'
import { Typography, Card, Switch } from "@mui/material";
import Layout from '../layouts';

const ContactPage = () => (
    <Layout pageName="Contact">
        <Card id="contactpage" style={{padding:'20px'}}>
            <Typography variant="h6" style={{color:'#080',fontWeight:'bold'}}>Need Assessment Quiz:</Typography>
            <br />
            <Switch color="primary" /><span className="contact">Would you like to create and maintain a diverse and inclusive
                work culture?</span>

            <br/>
            <Switch color="primary"/><span className="contact">Would you benefit from stronger teamwork within your
                organization?</span>

            <br/>
            <Switch color="primary"/><span className="contact">Do you observe decreased productivity due to unresolved
                and mismanaged conflicts between employees?</span>

            <br/>
            <Switch color="primary"/><span className="contact">Do your organization's leaders need further development
                and coaching to get better results, avoid burn out, and experience more job satisfaction?</span>

            <br/>
            <Switch color="primary"/><span className="contact">Do you notice decrease in employee morale and productivity
                due to employees reeling from constant change?</span>

            <br/>
            <Switch color="primary"/><span className="contact">Would your organization benefit from more creativity and
                innovation?</span>

            <br/>
            <Switch color="primary"/><span className="contact">Are people stuck in victim mentality?</span>

            <br/>
            <Switch color="primary"/><span className="contact">Is your organization ready to move to the next step in
                maximizing employee diversity?</span>

            <br/>
            <Switch color="primary"/><span className="contact">Are you and your employees tired of the same old training
                sessions that do not get results?</span>

            <br/>
            <Switch color="primary"/><span className="contact">Would you be interested in using dynamic and effective
                Accelerated Learning™ processes that get results?</span>

            <br/>
            <Switch color="primary"/><b className="contact">Are you and your organization ready for more success?</b>

            <br/>
            <br/>
            <Typography variant="subtitle1" style={{color:'#080',fontWeight:'bold'}}>If you answered <em>Yes</em> to any
                of the above questions, you will benefit from using our services.</Typography>

            <br/>
            <div>Telephone: <a class="contactlink" href="tel:+12065230270">(206) 523-0270</a></div>
            <div>Email: <a class="contactlink" href="mailto:hcho@synergyct.net">hcho@synergyct.net</a></div>

        </Card>
    </Layout>
);

export default ContactPage;
